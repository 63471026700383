/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.RecentlyViewedWidget {
    &-Wrapper {
        margin-block-end: 64px;
        padding-inline: 0;
    
        @include desktop {
            margin-block-end: 120px;
        }
    
        h2 {
            margin-block-end: 20px;
            font-size: 24px;
            line-height: 28px;
            font-weight: 250;
    
            @include desktop {
                margin-block-end: 40px;
                font-size: 40px;
                line-height: 48px;
            }
        }
    }
}
